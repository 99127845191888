@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');


.from-dedukt-bl{
    background-color: #10008B !important;
    border: #10008B !important;
}
.bg-dedukt{
    background-color: #10008B !important;
    border: #10008B !important;
}
.privacy-btn{
    margin-bottom: -200px !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  scroll-behavior: smooth;
    font-family:'Montserrat' !important;
}

a{
    /*text-decoration: none;*/
    /*color: unset;*/
}
.br-10{
    border-radius: 10px;
}
.font-size-56{
    font-size: 56px!important;
}
.font-weight-700{
    font-weight: 700!important;
}
.font-weight-300{
    font-weight: 300!important;
}
.font-size-16{
    font-size: 16px!important;
}

.m-lg-7{
    margin: 7% !important;
}

html {
    font-family:'Montserrat';
    margin: 0;
  padding: 0;
    width: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden !important;
}

body {
    font-family: 'Montserrat';
    margin: 0;
  padding: 0;
    width: 100%;
    overflow-x: hidden !important;
}

ul{
    list-style-type: none;
}

hr{
    color: black;
}

/*'sm': '640px',*/
      @media (min-width: 640px) { 

			 }

/*Small devices (320px)*/
@media only screen
and (min-device-width: 320px) and (max-device-width: 538px)
 { 
    .navbutn{
    position: absolute !important;
}

.holdContainer{
    max-width: 100% !important;
    margin: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

/*.holdContainer{
    max-width: 0px !important;
    margin: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}*/
    
    .navsmall{
    margin-top: 100px !important;
}
    .herospacing{
    padding-top: 100px !important;
    padding-left: 14px !important;
padding-right: 14px !important;
}

.countbod{
    border-top: none !important;
}

.headerround{
    margin-bottom: -40px !important;
    margin-top: -340px !important;
}

.headerimg {
    margin-bottom: 0px !important;
    margin-top: -200px !important;
}

.whytextt {
    padding-right: 10px !important;
}

    .privibtn{
        padding-bottom: 5px !important;
        padding-top: 5px !important;
    }

    .deduktneed {
    margin-left: 0px !important;
        padding-right: 2px !important;
    }

  .deduktmarginn{
        margin-top: 128px !important;
    }

    .loanspacing {
    margin-left: -30px !important;
    margin-right: -0px !important;
        padding-left: 30px !important;
}

.howtext {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 40px !important;
        font-size: 18px !important;
    padding-right: 3px !important;
}

    .whybtn {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-left: 9px !important;
}

.deduktspacingg{
    padding-top: 160px !important;
}

.buildspcing{
    margin-right: -60px !important;
}

.achieve-rggg {
    margin-left: 0px !important;
    padding-right: 10px !important;
    margin-top: -90px !important;
    margin-bottom: -90px !important;
    }

.valcontt{
    margin-right: -150px !important;
}

.partnerspacing{
    padding-top: 150px !important;
}

.fom-row {
    margin-right: -400px !important;
    padding-right: 50px !important;
    margin-left: -110px !important;
	}

.form-namee .form-control{
    /*background-clip: linear-gradient(to right, #10008B, #98E2EC) !important;*/
border-color: #10008B !important;
width: 250px !important;
}

.form-maill .form-control {
    border-color: #10008B !important;
    width: 200px !important;
    }

    .form-msgg .form-control {
    width: 255px !important;
        border-color: #10008B !important;
    }

    .form-btnn {
    width: 250px !important;
    }

    .iconn{
        margin-top: 0px !important;
    margin-left: -12px !important;
    }

    .form-req .form-control {
    width: 300px !important;
    margin-left: 20px !important;
}

.reqdemotext{
    font-size: 16px !important;
}

.modallbdy{
    right: -16px !important;
    top: -94px !important;
}

.form-req .form-label {
    margin-left: -220px !important;
        font-size: 20px !important;
}

.form-comp .form-control {
    width: 300px !important;
    margin-left: 20px !important;
}

.form-comp .form-label {
    margin-left: -120px !important;
        font-size: 18px !important;
}

.form-maila .form-control {
    width: 300px !important;
    margin-left: 18px !important;
}

.form-maila .form-label {
    margin-left: -120px !important;
        font-size: 18px !important;
}

.form-btnreq {
    width: 300px !important;
        margin-left: 0px !important;
        margin-right: -9px !important;
    }
    
    
    .container .logo {
    margin-left: 10px !important;
}


.emp{
display: none !important;
}

.heroimg{
    display: none !important;
}

.herooimg{
margin-top: -500px !important;
margin-bottom: 80px !important;
}

.bord{
    display: none !important;
}
.len{
display: none !important;
}

.men-icon{
    margin-left: 100px !important;
}

.lenimgg{
    display: none !important;
}

.lenoimg{
margin-top: -500px !important;
margin-bottom: 80px !important;
}

.herofirst{
    font-size: 28px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
    text-align: left !important;
    margin-bottom: 32px !important;
}

.herosecond{
font-size: 12px !important;
}

.startedbtn{
    padding-left: 10px !important;
  margin-bottom: 50px !important;
}

.startedbtnn{
    padding-left: 10px !important;
  margin-bottom: 50px !important;
}

.counterhero{
margin-bottom: 50px !important;
}

.counterbul{
    display: none !important;
}
.headerround {
    width: 300px !important;
    height: 300px !important;
    border-radius: 60% !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: -60px !important;
    padding-left: 20px !important;
}


.privi {
        padding-top: 0px !important;
    padding-right: 40px !important;
    padding-left: 20px !important;
        margin-right: -40px !important;
}

.privibtn {
    font-size: 14px !important;
    margin-top: 22px !important;
    margin-bottom: 0px !important;
    width: 60% !important;
}

.headerround {
    background-image: none !important;
}

.policybtn .privibtn {
    margin-top: 62px !important;
    width: 80% !important;
        font-size: 10px !important;
    }

 .privipolicy {
    margin-right: -20px !important;
}

.imgheader{
    margin-left: 6px !important;
    width: 100% !important;
}

.achieveround{
    display: none !important;
}

.achieveecont{ 
    padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.access-rgg {
    margin-left: 2px !important;
    margin-top: 1px !important;
}
.achievefirstt{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
}

.achievee{
text-align: center !important;
}

.achieveesec{
    text-align: center !important;
}

.achievesecondd{
    font-size: 14px !important;
font-family: 'CamptonBook' !important;
font-weight: 400 !important;
}

.accessround{
display: none !important;
}

.accesscontt{
    padding-top: 48px !important;
  padding-bottom: 16px !important;
    background-image: #10008B
}

.accessfirstt{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
    text-align: center !important;
}

.accesssecondd{
    font-size: 14px !important;
font-family: 'CamptonBook' !important;
font-weight: 400 !important;
    color: white !important;
    text-align: center !important;
}

.accesssbtn {
    background-image: linear-gradient(to right, #F5F5F5, #FFFFFF) !important;
color: #10008B !important;
} 

.unlockgrid{
padding-left: 0px !important;
    padding-right: 0px !important;
    align-content: center !important;
    text-align: center !important;
}

.unlockfirst{
font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
    text-align: left !important;
    margin-bottom: 32px !important;
    margin-top: 60px !important;
    /*text-align: center !important;*/
}

.unlocksecond{
font-size: 14px !important;
font-family: 'CamptonBook' !important;
font-weight: 400 !important;
/*text-align: center !important;*/
}

.unlockmargin{
    margin-right: 0px !important;
}

.unlock{
    margin-left: -30px !important;
    height: 400px !important;
    width: 250px !important;
}

.whyfirst{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
    margin-left: 30px !important;
}

.whysecond{
    margin-right: 0px !important;
    padding-left: 0px !important;
}

.whythird {
    font-size: 14px !important;
}

.whybtn{
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.whyabt{
    display: none !important;
}

.howfirst{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
}

.howdiv {
margin-left: -18px !important;
}

.howsecond {
    text-align: left !important;
    font-size: 14px !important;
}

.howtextfirst {
    padding-right: 16px !important;
}

.howtextsecond {
    padding-right: 25px !important;
  margin-left: -45px !important;
}

.howimg {
display: none !important;
}

.getstarted{
padding-left: 80px !important;
    padding-right: 96px !important;
    margin-bottom: 120px !important;
    margin-top: 50px !important;
}

.startedfirst{
    font-size: 14px !important;
}

.whoalign {
    padding-right: 60px !important;
    padding-left: 0px !important;
    margin-left: -15px !important;
}

.startedsecond {
    font-size: 32px !important;
}

.whopadding{
padding-top: 80px !important;
padding-bottom: 150px !important;
}

.whoheading{
    padding-left: 4px !important;
}

.whotext{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
}

.whoalign{
margin-right: 16px !important;
}

.whobtn {
    padding-left: 40px !important;
    padding-right: 40px !important;
        margin-left: -20px !important;
}

.whotextfirst {
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
}

.whotextsecond{
    font-size: 14px !important;
}

.whoimg{
    display: none !important;
}

.valuetext{
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
    padding-left: 45px !important;
}

.valcontt {
    margin-right: -10px !important;
    margin-left: -40px !important;
}

.value{
    margin-top: 165px !important;
}

.valuespacing{
padding-left: 45px !important;
}

.valuespace{
    margin-right: 1px !important;
  /*padding-left: 20px !important;*/
}
.valuefirst {
    font-size: 18px !important;
}

.valuesecond{
    font-size: 14px !important;
    text-align: left !important;
}

.abtimg{
display: none !important;
}

.growingtext{
font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'CamptonSemiBold' !important;
}

.growingone{
    margin-left: 150px !important;
}

.growingtwo{
    display: none !important;
}

.growingthree{
    margin-left: 190px !important;
    margin-top: 70px !important;
}

.growingfour{
    margin-left: 240px !important;
    margin-top: 90px !important;
}

.reqdemobtn{
padding-left: 45px !important;
padding-right: 45px !important;
}

.getsupportimg{
    display: none !important;
}

.form-contt {
    max-width: 0px !important;
        height: 50px !important;
        margin-right: 170px !important;
    margin-top: -450px !important;
    }

    .form-comp .form-label{
        margin-left: -30px !important;
    }

    .form-req .form-label {
    margin-left: -130px !important;
}

    .form-maila .form-label{
        margin-left: -50px !important;
    }


    .form-btnreq{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

    .form-namee .form-control {
    width: 200px !important;
    margin-left: -20px !important;
}

.form-namee .form-label {
    margin-left: -20px !important;
}

.form-maill .form-control {
    margin-left: -20px !important;
}

.form-maill .form-label {
    margin-left: -20px !important;
}

.form-msgg .form-label {
    margin-left: -20px !important;
}

.form-msgg .form-control {
    height: 150px !important;
    width: 200px !important;
    margin-left: -20px !important;
}

.form-btnn {
width: 220px !important;
margin-left: -30px !important;
}

.deduktlogoo {
    width: 100% !important;
    padding-left: 2px !important;
    margin-left: 15px !important;
}

.sign-in{
    margin-left: -50px !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.get-started{
    margin-left: -25px !important;
  margin-top: 5px !important;
}

.img-header {
    padding-top: 120px !important;
    padding-bottom: 60px !important;
    }

.promotion-logo{
        margin-left: -160px !important;
}

.head-padding{
    margin-left: 30px !important;
}

.big-text {
    font-size: 25px !important;
    }

    .promotion {
    /*font-size: 24px !important;*/
        display: none !important;
    }

    .promotion-logo{
        display: none !important;
    }

    .promotion-padding {
    padding-top: 0px !important; 
    padding-bottom: 0px !important;
    }

    .about-bg {
     /*padding-top: 0px !important; */
    margin-top: -400px !important;
    margin-bottom: 150px !important; 
    }
    .about-img{
        /*max-width: 500px !important;
    height: 250px !important;
    margin-left: 0px !important;
        padding-bottom: 450px !important;*/
        background-image: none !important;
    }

    .about-text h1 {
    font-size: 25px !important;
    }

    .about-text p {
    font-size: 15px !important;
    }

    .build-img {
    max-width: 340px !important;
    height: 340px !important;
        margin-left: 20px !important;
        padding-bottom: 550px !important;
    }

    .build-text h1 {
    font-size: 22px !important;
    }

    .build-text p {
    font-size: 15px !important;
    }

    .achieve-img{
        max-width: 270px !important;
    height: 330px !important;
        margin-left: 30px !important;
        padding-bottom: 450px !important;
    }

    .achieve-text h1 {
    font-size: 30px !important;
    }

    .achieve-text p {
    font-size: 15px !important;
    }

    .access-img {
        max-width: 380px !important;
        /*height: 380px !important;*/
        margin-left: 30px !important;
    }

    .achieve-rg {
    margin-top: 0px !important;
    }

    .achieve-bg {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    }

    .access-text h1{
  font-size: 26px !important;
}

.access-text p {
    font-size: 15px !important;
    }

.access-rg {
    margin-left: 20px !important;
        padding-top: 20px !important;
    }

.access-bg {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    }

    .btn-started {
    font-size: 13px !important;
    }

    .start-small h3 {
        padding-top: 30px !important;
    }

    .round {
        margin-left: 120px !important;
    }

    .support-big {
    font-size: 24px !important;
    }

    .support-small {
    font-size: 10px !important;
    }

    .support-logo{
    background-image: none !important;
    }

    .support-padding {
    padding-top: 500px !important;
    padding-bottom: 50px !important;
        margin-left: 10px !important;
    }

    .support-text{
        margin-bottom: 500px !important;
    margin-left: 0px !important;
        margin-top: -450px !important;
    }

.supptfirst{
font-size: 24px !important;
font-weight: 600 !important;
font-family: 'CamptonSemiBold' !important;
text-align: left !important;
    }

    .supptsecond{
        font-size: 12px !important;
font-weight: 500 !important;
font-family: 'CamptonMedium' !important;
text-align: left !important;
    }
}

/*Iphone SE (375px)*/
@media only screen and (min-device-width: 375px) and (max-device-height: 667px){
.fom-row {
    margin-right: -400px !important;
    padding-right: 50px !important;
    margin-left: -110px !important;
	}
}

/*Iphone 12 Pro (390px)*/
@media only screen and (min-device-width: 390px) and (max-device-height: 844px){
}

/*Iphone XR (414px)*/
@media only screen and (min-device-width: 414px) and (max-device-height: 896px){
.fom-row {
    margin-right: -400px !important;
    padding-right: 50px !important;
    margin-left: -140px !important;
}

}

@media only screen
and (max-device-width: 1024px)  {
    h1{
        font-size: 26px !important;
    }
    h2{
        font-size: 25px !important;
    }
    div{
        font-size: 26px !important;
    }
    p{
        font-size: 10px !important;
    }
    a{
        font-size: 15px !important;
    }
    span{
        font-size: 25px !important;
    }
}

@media only screen
and (max-device-width: 768px)  {
    .fom-row {
        margin-right: 0px !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
    }
    
}

@media only screen
and (max-device-width: 576px)  {
    .text-sm-center{
        text-align: center !important;
    }
    .justify-content-sm-center{
        justify-content: center !important;
    }
    .m-lg-7{
        margin: 0% !important;
    }
    .d-sm-none{
        display: none;
    }
    .d-sm-block{
        display: block !important;
    }
    .m-sm-2{
        margin: 10px;
    }
    .mt-sm-5{
        margin-top: 50px;
    }
    .m-sm-1{
        margin: 10px;
    }
    .p-sm-1{
        margin: 10px;
    }
	.p-sm-0{
		padding: 0px !important;
	}
	.m-sm-0{
		margin: 0px !important;
	}
	.text-sm-small{
		font-size: 20px !important;
	}

		.navpositioning{
			padding-left: 40px !important;
		}

		.howpart{
			padding-top: 40px !important;
    padding-bottom: 40px !important;
		}

		.getstarted {
    padding-left: 120px !important;
    padding-right: 120px !important;
    margin-bottom: 120px !important;
    margin-top: 50px !important;
}

.abtt{
	padding-right: 0px !important;
    margin-left: -40px !important;
		margin-top: 50px !important;
}

.fom-row{
	margin-right: -400px !important;
    padding-right: 50px !important;
    margin-left: -120px !important;
}

.form-namee .form-control {
    width: 300px !important;
    margin-left: -20px !important;
}

.form-maill .form-control {
    border-color: #10008B !important;
    width: 300px !important;
		margin-left: -20px !important;
}

.form-msgg .form-control {
    height: 150px !important;
    width: 300px !important;
    margin-left: -20px !important;
}


.form-btnn {
    width: 300px !important;
    margin-left: -140px !important;
}
.privacy-btn{
    margin-bottom: 0px !important;
}

}
@media only screen
and (max-device-width: 319px)  {
   
    .font-size-sm{
        font-size: 20px;
    }
    .m-sm-1{
        margin: 10px;
    }
    .p-sm-1{
        margin: 10px;
    }
}
/*NestHub (1024px)*/

/*Ipad Mini (1024px)*/
@media only screen
and (min-device-width: 768px) 
and (max-device-width: 1024px) { 
    .container .logo {
    margin-left: 0px !important;
}

.promotion-logo{
        margin-left: -160px !important;
}

.head-padding{
    margin-left: 60px !important;
}

.big-text {
    font-size: 30px !important;
    }

    .about-text h1 {
    font-size: 30px !important;
    }

    .about-text p {
    font-size: 15px !important;
    }

    .build-text h1 {
    font-size: 30px !important;
    }

    .build-text p {
    font-size: 15px !important;
    }

    .achieve-img{
        max-width: 390px !important;
    }

    .achieve-text h1 {
    font-size: 30px !important;
    }

    .achieve-text p {
    font-size: 15px !important;
    }

    .access-img {
        max-width: 380px !important;
        margin-left: 30px !important;
    }

    .access-text h1{
font-size: 28px !important;
}

.access-text p {
    font-size: 15px !important;
    }

.access-rg {
    margin-left: 40px !important;
        padding-top: 50px !important;
    }

    .btn-started {
    font-size: 13px !important;
    }

    .round {
        margin-left: 80px !important;
    }

}


/*Ipad Air (1180px)*/
@media only screen
and (min-device-width: 820px) 
and (max-device-width: 1180px) { 
    .container .logo {
    margin-left: 0px !important;
}

.sign-in{
    margin-left: -50px !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.get-started{
    margin-left: -25px !important;
  margin-top: 5px !important;
}

.img-header {
    padding-top: 140px !important;
    padding-bottom: 60px !important;
    }

.promotion-logo{
        margin-left: -160px !important;
}

.head-padding{
    margin-left: 60px !important;
}

.big-text {
    font-size: 25px !important;
    }

    .promotion {
    font-size: 26px !important;
    }

    .promotion-padding {
    padding-bottom: 65px !important;
    }

    .about-img{
        max-width: 360px !important;
    height: 390px !important;
    margin-left: 0px !important;
        padding-bottom: 800px !important;
    }

    .about-rg {
    margin-left: 180px !important;
    }

    .about-bg {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
    }


    .about-text h1 {
    font-size: 25px !important;
    }

    .about-text p {
    font-size: 15px !important;
    }

    .build-img {
    max-width: 360px !important;
    height: 390px !important;
        margin-left: 60px !important;
        padding-bottom: 550px !important;
    }

    .build-rg {
    margin-left: 80px !important;
}

    .build-text h1 {
    font-size: 28px !important;
    }

    .build-text p {
    font-size: 15px !important;
    }

    .achieve-img{
        max-width: 330px !important;
    height: 330px !important;
        margin-left: 60px !important;
        padding-bottom: 450px !important;
    }

    .achieve-rg {
    margin-left: 60px !important;
    }

    .achieve-text h1 {
    font-size: 28px !important;
    }

    .achieve-text p {
    font-size: 15px !important;
    }

    .access-img {
        max-width: 330px !important;
        height: 380px !important;
        margin-left: 60px !important;
    }

    .access-text h1{
  font-size: 28px !important;
}

.access-text p {
    font-size: 15px !important;
    }

.access-rg {
    margin-left: 60px !important;
        padding-top: 80px !important;
    }

    .btn-started {
    font-size: 13px !important;
    }

    .round {
        margin-left: 90px !important;
    }

    .support-big {
    font-size: 28px !important;
    }

    .support-small {
    font-size: 15px !important;
    }

    .form-mail .form-control {
    width: 100px !important;
    }

    .form-name .form-control {
    width: 100px !important;
    }

    .form-msg .form-control {
    width: 240px !important;
    }

    .form-btn {
    width: 240px !important;
    }

    .form-cont {
    max-width: 290px !important;
        margin-right: 0px !important;
    }

}


/* For surface Duo(1368px)*/
@media only screen
and (min-device-width: 912px) 
and (max-device-width: 1368px) { 
    .container .logo {
    margin-left: -70px !important;
}

.sign-in{
    margin-left: -50px !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.get-started{
    margin-left: -25px !important;
  margin-top: 5px !important;
}

.img-header {
    padding-top: 120px !important;
    padding-bottom: 60px !important;
    }

.promotion-logo{
        margin-left: -10px !important;
        padding-top: 40px !important;
}

.head-padding{
    margin-left: 30px !important;
}

.big-text {
    font-size: 25px !important;
    }

    .promotion {
    font-size: 26px !important;
    }

    .promotion-padding {
    padding-bottom: 65px !important;
    }

    .about-img{
        max-width: 360px !important;
    height: 390px !important;
    margin-left: 60px !important;
        padding-bottom: 750px !important;
    }

    .about-rg {
    margin-left: 280px !important;
    }

    .about-bg {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
    }


    .about-text h1 {
    font-size: 25px !important;
    }

    .about-text p {
    font-size: 15px !important;
    }

    .build-img {
    max-width: 360px !important;
    height: 390px !important;
        margin-left: 60px !important;
        padding-bottom: 550px !important;
    }

    .build-rg {
    margin-left: 60px !important;
}

    .build-text h1 {
    font-size: 28px !important;
    }

    .build-text p {
    font-size: 15px !important;
    }

    .achieve-img{
        max-width: 330px !important;
    height: 330px !important;
        margin-left: 60px !important;
        padding-bottom: 450px !important;
    }

    .achieve-rg {
    margin-left: 60px !important;
    }

    .achieve-text h1 {
    font-size: 28px !important;
    }

    .achieve-text p {
    font-size: 15px !important;
    }

    .access-img {
        max-width: 330px !important;
        /*height: 380px !important;*/
        margin-left: 60px !important;
    }

    .access-text h1{
  font-size: 28px !important;
}

.access-text p {
    font-size: 15px !important;
    }

.access-rg {
    margin-left: 60px !important;
        padding-top: 80px !important;
    }

    .btn-started {
    font-size: 13px !important;
    }

    .round {
        margin-left: 90px !important;
    }

    .support-big {
    font-size: 28px !important;
    }

    .support-small {
    font-size: 15px !important;
    }

    .form-mail .form-control {
    width: 100px !important;
    }

    .form-name .form-control {
    width: 100px !important;
    }

    .form-msg .form-control {
    width: 240px !important;
    }

    .form-btn {
    width: 240px !important;
    }

    .form-cont {
    max-width: 290px !important;
        margin-right: 0px !important;
    }

}


/*@media (min-width: 640px) { 

}


@media (min-width: 768px) { 

}*/
/*Large devices (desktops, 1024px and up)*/
@media screen and (min-device-width: 1024px) and (max-device-width: 1247px) { 
.navbutn{
    white-space: nowrap !important;
}

.heroimg{
    margin-top: 40px !important;
}

.unlockpaad{
    padding-top: 32px !important;
}

.navspcing{
    margin-left: 10px !important;
}

.counterhero{
    margin-right: -100px!important;
}

.herospacing{
    padding-top: 80px !important;
}

.lenbtnn{
    margin-bottom: 22px !important;
}

.headerimg{
    width: 55% !important;
    padding-left: 30px !important;
  margin-top: -100px !important;
}

.privibtn {
    margin-bottom: 25px !important;
}
.acessspacing{
    margin-right: -100px !important;
    margin-left: 20px !important;
}

.privispacingg {
margin-right: -110px !important;
}
.unlockgrid{
    padding-left: 8px !important;
    padding-right: 100px !important;
}

.whyabt {
    margin-top: 170px !important;
    margin-left: 30px !important;
}

.deduktneed{
    margin-left: 50px !important;
    margin-right: -60px !important;
}

.whybtn {
    padding-left: 110px !important;
    padding-right: 110px !important;
}

.howdiv{
    margin-left: -20px !important;
}

.loanspacing{
    padding-left: 16px !important;
}

.howtext{
    padding-right: 16px !important;
}

.whoheading {
    margin-left: -10px !important;
}

.whoimg {
    padding-left: 40px !important;
    margin-right: 0px !important;
}

.valheading {
    margin-left: 0px !important;
    margin-right: 100px !important;
}

.form-contt{
    margin-right: -90px !important;
}

.deduktlogoo{
    padding-left: 80px !important;
}

.achieveround{
margin-left: -8px !important;
}

.achieve-rggg{
margin-right: 130px !important;
    padding-left: 90px !important;
    padding-right: 0px !important;
    }

    .getstarteddspacing{
        padding-top: 16px !important;
    }

.accessround{
    margin-left: 10px !important;
}

/*.accessround{
    margin-left: 140px !important;
}

.achieve-rgg{
    padding-left: 0px !important;
}*/

}

@media (min-width: 1248px) { 
.achieveround{
    margin-left: -30px !important;
    padding-left: 100px !important;
}

.lenimgg{
    margin-top: -110px !important;
}

.lenbtnn {
    margin-bottom: 100px !important;
}

.achievebod{
padding-left: 30px !important;
    margin-right: -80px !important;
    margin-left: 40px !important;
}

.getstarteddspacing{
    padding-top: 16px !important;
}

.herospacing{
    padding-top: 150px !important;
}

.headerimg {
    margin-top: 20px!important;
    padding-left: 30px!important;
    width: 55%!important;
}

.accessround{
    margin-left: 230px !important;
}

.achieve-rgg{
    padding-left: 0px !important;
}

.counterhero{
    margin-right: 30px!important;
    margin-left: 0px !important;
}

}

@media (min-width: 1280px){

}

@media screen and (min-width: 1366px){
.achieveround{
    padding-left: 100px !important;
}
}
/*max-width: var(--max-width,1248px);
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;*/
@media screen and (min-device-width: 1536px) and (max-device-width: 1919px) { 
.privibtn {
    margin-bottom: 34px !important;
}

.whoheading {
    margin-left: 0px !important;
    padding-left: 60px !important;
    margin-right: 120px !important;
}

.whoimg {
    padding-left: 180px !important;
}

.acessspacing{
    margin-right: -100px !important;
    margin-left: 250px !important;
}

.privispacingg {
margin-right: -330px !important;
}

}

@media screen and (min-width: 1920px) { 
.herofirst{
	/*/*padding-right: 200px !important;*/
	font-size: 60px !important;
}

.herosecond {
	/*padding-right: 50px !important;*/
	font-size: 30px !important;
	line-height: 2.5rem !important;
}

.navspacing{
	padding-left: 100px !important;
}

.privibtn {
    margin-bottom: -1px !important;
}

.unlockfirst{
	font-size: 60px !important;
}

.unlocksecond{
	font-size: 30px !important;
    line-height: 2.5rem !important;
}

.navsizing{
	font-size: 28px !important;
}

/*.herooimg{
	padding-right: 300px !important;
}*/

.whysecond{
	margin-right: 250px !important;
}

.whyfirst{
	font-size: 50px !important;
    line-height: 3rem !important;
}

.whytt{
	font-size: 30px !important;
    line-height: 2rem !important;
}

.whythird{
	font-size: 28px !important;
    line-height: 2rem !important;
}

.howfirst{
	font-size: 50px !important;
    line-height: 2.25rem !important;
}

.howsecond{
    font-size: 35px !important;
    line-height: 2.5rem !important;
    letter-spacing: -2px !important;
}

.howtext{
	font-size: 35px !important;
    line-height: 2.5rem !important;
}

.studyimg{
	margin-right: 280px !important;
}

.startedfirst{
	font-size: 30px !important;
}

.whotext{
	font-size: 50px !important;
    line-height: 3rem !important;
}

.whotextfirst{
	font-size: 30px !important;
    line-height: 2rem !important;
}

.whotextsecond{
	font-size: 28px !important;
    line-height: 2rem !important;
}

.whospacingg{
	padding-right: 90px !important;
}

.whopaddinggg{
	padding-top: 200px !important;
    padding-left: 300px !important;
}

.valuetext{
	font-size: 50px !important;
    line-height: 3rem !important;
}

.valuefirst{
	font-size: 30px !important;
    line-height: 2rem !important;
}

.valuesecond{
	font-size: 28px !important;
    line-height: 2rem !important;
}

.abtimgg{
padding-right: 200px !important;
}

.abtded{
	padding-left: 200px !important;
}

.growingtext{
	font-size: 50px !important;
    line-height: 2.5rem !important;
}

	.access-rgg {
    margin-left: 100px !important;
    padding-left: 80px !important;
    margin-top: 120px !important;
}

.achieveround {
    padding-left: 100px !important;
        margin-left: -40px !important;
}

.accessbod{
    padding-left: 80px !important;
}

.accessround {
    margin-left: 250px !important;
    padding-right: 0px !important;
        
}

.achievebod {
    margin-left: 10px !important;
}

.lenbtnn {
    margin-bottom: 80px !important;
    margin-top: 0px !important;
}

}


@media screen and (min-device-width: 1980px) and (max-device-width: 2559px) { 
.privibtn{
    margin-bottom: -580px !important;
    margin-top: 280px !important;
    position: sticky !important;
}

.acessspacing{
    margin-right: -500px !important;
    margin-left: 250px !important;
}

.privispacingg {
margin-right: -290px !important;
}

.achieveround{
    margin-left: -230px !important;
}

.acessspacing{
    margin-right: -380px !important;
}

.accessround{
    padding-left: 120px !important;
}

.achieve-rggg{
    margin-left: 40px !important;
    padding-right: 60px !important;
        padding-left: 60px !important;
}

.whoheading {
    padding-right: 290px !important;
    margin-left: 100px !important;
}
.whoimg {
    padding-left: 400px !important;
}

}

@media (min-width: 2560px) { 
.herofirst{
	/*/*padding-right: 200px !important;*/
	font-size: 80px !important;
}

.herosecond {
	/*padding-right: 50px !important;*/
	font-size: 30px !important;
	line-height: 2.5rem !important;
}

.navspacing{
	padding-left: 700px !important;
}

.unlockfirst{
	font-size: 80px !important;
}

.unlocksecond{
	font-size: 35px !important;
    line-height: 2.5rem !important;
}

.navsizing{
	font-size: 28px !important;
}

.herooimg{
	padding-right: 300px !important;
}

.whysecond{
	margin-right: 450px !important;
}

.whyfirst{
	font-size: 50px !important;
    line-height: 3rem !important;
}

.whytt{
	font-size: 30px !important;
    line-height: 2rem !important;
}

.whythird{
	font-size: 28px !important;
    line-height: 2rem !important;
}

.howfirst{
	font-size: 50px !important;
    line-height: 2.25rem !important;
}

.howsecond{
    font-size: 35px !important;
    line-height: 2.5rem !important;
}

.howtext{
	font-size: 35px !important;
    line-height: 2.5rem !important;
}

.studyimg{
	margin-right: 280px !important;
}

.startedfirst{
	font-size: 30px !important;
}

.whotext{
	font-size: 50px !important;
    line-height: 3rem !important;
}

.whotextfirst{
	font-size: 30px !important;
    line-height: 2rem !important;
}

.whotextsecond{
	font-size: 28px !important;
    line-height: 2rem !important;
}

.whospacingg{
	padding-right: 350px !important;
}

.whopaddinggg{
	padding-top: 200px !important;
    padding-left: 300px !important;
}

.valuetext{
	font-size: 50px !important;
    line-height: 3rem !important;
}

.valuefirst{
	font-size: 30px !important;
    line-height: 2rem !important;
}

.valuesecond{
	font-size: 28px !important;
    line-height: 2rem !important;
}

.abtimgg{
padding-right: 200px !important;
}

.abtded{
	padding-left: 200px !important;
}

.growingtext{
	font-size: 50px !important;
    line-height: 2.5rem !important;
}

	.achieveround{
    margin-left: -50px !important;
}

.achievespacee{
    margin-right: 450px !important;
}

.accessbod{
    margin-left: 120px !important;
}

/*.privibtn{
    margin-bottom: -980px !important;
    margin-top: 860px !important;
    position: sticky !important;
}*/

.deduktlogoo{
    padding-left: 20rem !important;
}


.whoimg{
    margin-left: 580px !important; 
}

.whoheading{
    padding-right: 580px !important;
    margin-left: 80px !important;
    margin-left: 80px !important;
    margin-right: 40px !important;
}

.acessspacing{
    margin-right: -700px !important;
    margin-left: 250px !important;
}

.privispacingg {
margin-right: -1210px !important;
}

.accessround{
    padding-left: 150px !important;
    margin-left: 130px !important;
}

.achievebod {
    padding-left: 150px !important;
    margin-right: 10px !important;
        margin-left: 200px !important;
}

.lenbtnn {
    margin-bottom: 440px !important;
}

.achieve-rggg{
    margin-left: 80px !important;
    padding-right: 160px !important;
        padding-left: 0px !important;
}

@media (min-width: 2880px) { 
.achieveround {
    margin-left: -690px!important;
        padding-left: 100px !important;
}

.accessround {
    padding-left: 150px!important;
        margin-left: 240px!important;
}

.privibtn .lenbtnn{
    margin-bottom: -330px !important;
    margin-top: 300px !important;
}

.deduktt{
    padding-left: 300px !important;
    padding-right: 300px !important;
}

}

}

@media screen and (min-width: 3072px)  {
    .lenbtnn {
    margin-bottom: -690px !important;
    margin-top: 250px !important;
}

.acessspacing {
    margin-left: 350px!important;
    margin-right: -800px!important;
} 

.accessround {
    padding-left: 100px!important;
        margin-left: 400px !important;
}

.achieveround {
    margin-left: -80px!important;
        padding-left: 150px !important;
}

.achievebod {
    padding-left: 150px !important;
    margin-right: 50px !important;
    margin-left: 400px !important;
}

.accessbod {
    margin-left: 10px !important;
    margin-right: 260px !important;
}

.achievespacee {
    margin-right: 990px !important;
}

.access-rgg {
    margin-left: 190px !important;
    }

    .privibtn .lenbtnn{
        margin-bottom: -950px;
    margin-top: 360px;
}

.deduktt{
    padding-left: 400px !important;
    padding-right: 350px !important;
}

}

/*--------------------------MEDIA QUERIES ENDS------------*/

/*--------------------------REQ DEMO MODAL STARTS------------*/
.holdContainer{
    max-width: var(--max-width, 1248px);
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
}
			  

      /*'lg': '1024px',*/
      @media (min-width: 1024px) { 
.holdContainer{
	max-width: var(--max-width, 1248px);
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
}
			 }

      /*'xl': '1280px',*/
       @media (min-width: 1280px) { 
.holdContainer{
	max-width: var(--max-width, 1248px);
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
}
			  }

      /*'2xl': '1536px',*/
       @media (min-width: 1536px) { 

			  }


/*--------------------------REQ DEMO MODAL STARTS------------*/

.holdContainer{
	max-width: 100% !important;
    margin: 0px !important;
	padding-left: 0px !important;
    padding-right: 0px !important;
}


.whytextt{
    padding-right: 100px;
}

.whybtn{
    padding-left: 160px;
    padding-right: 160px;
}

.whyabt{
    margin-top: 100px;
    margin-left: 60px;
}

.whoheading{
    margin-left: 30px;
}
.whoalign{
    padding-right: 100px;
}

.whoimg{
    padding-left: 80px;
}

.whobtn{
    padding-left: 160px;
    padding-right: 160px;
}

.abtimg{
    margin-left: 120px;
    margin-right: 0px;
}

.valheading{
    margin-left: 50px;
    margin-right: 50px;
}

.form-req .form-label {
    margin-left: -80px;
}

.form-req .form-control{
    width: 400px;
    margin-left: 25px;
    }

    .form-comp .form-label{
        margin-left: 20px;
    }

    .form-comp .form-control{
    width: 400px;
    margin-left: 25px;
    }

    .form-maila .form-label{
        margin-left: 10px;
    }

    .form-maila .form-control{
    width: 400px;
    margin-left: 25px;
    }

    .form-btnreq{
    width: 400px;
    margin-left: 40px;
    margin-right: 20px;
        margin-bottom: 30px;
    color: #FFFFFF;
}

/*--------------------------REQ DEMO MODAL ENDS------------*/

/*--------------------------PRIVACY DEMO MODAL ENDS------------*/
.privibtn{
  margin-bottom: -1px;
}

.privacy-btnreq{
    width: 150px;
    height: 47px;
}
/*--------------------------PRIVACY DEMO MODAL ENDS------------*/

/* --------------------HERO SECTION STARTS ------------------*/
.hero-space{
    letter-spacing: -0.045em;
}

.countbod{
    margin-top: 20px;
  margin-bottom: 20px;
}

.countbodd{
    margin-top: 20px;
  margin-bottom: 20px;
}

.countbodd{
	margin-top: 20px;
  margin-bottom: 20px;
}

/* --------------------UNLOCK SECTION ------------------*/
.unlock{
    width: 491px;
    height: 614px;
}

.aboutt{
    width: 510px;
    height: 664px;
}
/* --------------------HERO SECTION ENDS ------------------*/

/* --------------------NAVBAR STARTS ------------------*/
.navbar{
    position: sticky !important;
}

.container .logo {
margin-left: -80px;
}

.container .btn-spacing  {
margin-right: -60px !important;
}

.nav-link {
  font-size: 14px;
    font-family: 'Montserrat' !important;
    font-style: normal;
  font-weight: 500 !important;
  color: #333333;
  text-decoration: none;
  transition: all 0.3s;
}

.nav-padding{
    padding-top:15px;
    padding-bottom: 15px;
}

/*SignIn button*/
.nav-item .btn-sign {
    background-color:  hsla(188, 90%, 81%, 0.171) !important;
    color: #333333 !important;
    font-family: 'Montserrat' !important;
    border-radius: 2px !important;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 400 !important;
    text-align: center !important;
    width: 96.39px;
    height: 37px;
    /*vertical-align: top !important;*/
}

.navspcing{
margin-right: 5px !important;
}

.nav-item .btn-start {
    background-color:  #00B7D0 !important;
    color: #FFFFFF !important;
    font-family: 'Montserrat' !important;
    border-radius: 2px !important;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 400 !important;
    text-align: center !important;
    width: 96.39px;
  height: 37px;
    /*vertical-align: top !important;*/
}
/* --------------------NAVBAR ENDS ------------------*/

/* --------------------HEADER STARTS ------------------*/
.head-padding{
    padding-top: 120px;
    margin-left: 80px;
    align-items: center !important;
  justify-content: center !important;
}

.img-header{
    background-position:center !important;
    background-repeat:no-repeat !important;
    background-size:contain !important;
    max-width: 100%;
    padding-top: 98px;
    padding-bottom: 49px;
    height:auto;
    margin: 0;
}

.btn-started {
    background-color:  #00B7D0 !important;
    color: #FFFFFF !important;
    font-family: 'Montserrat' !important;
    border-radius: 2px !important;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 500 !important;
    text-align: center !important;
    /*vertical-align: top !important;*/
}

.big-text{
    font-family: 'Montserrat' !important;
    font-size: 40px;
    font-style: normal !important;
    font-weight: 700 !important;
    padding:20px 0px !important;
    color: #140740 !important;

}

.small-text {
  font-family: 'Montserrat' !important;
    font-size: 16px;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #333333 !important;
}

.promotion-padding{
    padding-top: 65px;
    padding-bottom: 125px;
}
        .promotion {
            font-family: 'Montserrat' !important;
            font-size: 34px;
            font-style: normal !important;
            font-weight: 700 !important;
            color: #140740 !important;
            padding-bottom: 80px;
        }

        .promotion-logo {
            background-position:center !important;
            background-repeat:no-repeat !important;
            background-size:contain !important;
            max-width: 100%;
            padding-top: 80px ;
            padding-left: 300px;
            justify-content: center;
            align-items: center;
            margin-left: 40px;
        }

        .counters {
            font-family: 'Montserrat' !important;
            font-size: 40px;
            font-style: normal !important;
            font-weight: 500 !important;
            color:#FFFFFF;
            padding: 10px ;
        }

        .countcol{
            background-color: #8780C5 !important;
            align-items: center;
        }

        .countpad{
            padding-top: 125px;
            padding-bottom: 125px;
        }

    .counters h3{
        font-size: 18px;
        font-family: 'Montserrat' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        color:#FFFFFF;
    }


/* --------------------HEADER ENDS ------------------*/

/* --------------------ABOUT US STARTS------------------*/
.about-bg{
    padding-top: 100px;
    padding-bottom: 125px;
    /*background-color: hsla(188, 90%, 81%, 0.171);*/
}

.about-img{
background-image: url('assets/Dedukt-about.gif');
background-position: center;
background-repeat:no-repeat !important;
background-size:contain !important;
max-width: 500px;
height: 500px;
/*margin-left: 60px;*/
padding-bottom: 800px;
}

.about-rg {
margin-left: 250px;
}

.about-text h1{
font-family: 'Montserrat' !important;
font-size: 36px;
font-style: normal !important;
font-weight: 600 !important;
color: #140740 !important;
text-align: left;
padding-bottom: 40px;
}

.about-text h2{
font-family: 'Montserrat' !important;
font-size: 22px;
font-style: normal !important;
font-weight: 600 !important;
color: #000000 !important;
text-align: left;
}

.about-text p {
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 600 !important;
color: #333333 !important;
font-size: 16px;
text-align: left;
}
/* --------------------ABOUT US ENDS ------------------*/


/* --------------------BUILD FOR STARTS------------------*/
.build-bg{
    padding-top: 125px;
    padding-bottom: 125px;
    background-color: hsla(188, 90%, 81%, 0.171);
}

.build-img{
background-image: url('assets/build.svg');
background-position: center;
background-repeat:no-repeat !important;
background-size:contain !important;
max-width: 500px;
height: 750px;
margin-left: 60px;
}

.build-rg {
margin-left: 200px;
}

.build-text h1{
font-family: 'Montserrat' !important;
font-size: 34px;
font-style: normal !important;
font-weight: 600 !important;
color: #140740 !important;
text-align: left;
padding-bottom: 40px;
}

.build-text h2{
font-family: 'Montserrat' !important;
font-size: 22px;
font-style: normal !important;
font-weight: 600 !important;
color: #000000 !important;
text-align: left;
}

.build-text p {
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 600 !important;
color: #333333 !important;
font-size: 16px !important;
text-align: left;
}

/* --------------------BUILD FOR ENDS------------------*/

/* --------------------ACHIEVE STARTS------------------*/
.achieve-bg{
    padding-top: 125px;
    padding-bottom: 125px;
    /*background-color: #E5E5E5;*/
}
.achieve-rg{
margin-left: 30px;
margin-top: 40px;
}

.achieve-rgg{
margin-left: 20px;
    margin-top: 100px;}

.achieve-img{
background-image: url('assets/achieve.svg');
background-position: center;
background-repeat:no-repeat !important;
background-size:contain !important;
max-width: 567px;
height: 448px;
margin-left: 120px;
}

.achieve-text h1{
font-family: 'Montserrat' !important;
font-size: 34px;
font-style: normal !important;
font-weight: 600 !important;
color: #140740 !important;
text-align: left;
padding-bottom: 30px;
}

.achieve-text p{
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 600 !important;
color: #333333 !important;
font-size: 16px;
text-align: left;
}

.btn-achieve{
    background-color:  #8780C5 !important;
    color: #FFFFFF !important;
    font-family: 'Montserrat' !important;
    border-radius: 2px !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    text-align: center !important;
}

/*access part in achieve*/
.access-bg{
padding-top: 140px;
padding-bottom: 140px;
background-color: hsla(188, 90%, 81%, 0.171);
}

.access-img{
    background-image: url('assets/access.png');
    background-position: center;
    background-repeat:no-repeat !important;
    background-size:contain !important;
    max-width: 567px;
  height: 448px;
    margin-right: 30px;
}

.accessimg{
    background-image: url('assets/accessimg.svg');
    background-position: center;
    background-repeat:no-repeat !important;
    background-size:contain !important;
    max-width: 628.95px;
  height: 363px;
    margin-left: 150px;
}

.achieveimg{
    background-image: url('assets/achieveimg.svg');
    background-position: center;
    background-repeat:no-repeat !important;
    background-size:contain !important;
    max-width: 546px;
  height: 356px;
    margin-left: 30px;
    margin-right: 50px;
}

.achieveround {
border-top-right-radius: 64px;
border-bottom-right-radius: 64px;
padding: 20px;
width: 900px;
height: 636px;
display: flex;
padding-left: 40px;
}

.accessround{
    border-top-left-radius: 64px;
border-bottom-left-radius: 64px;
padding: 20px;
width: 900px;
height: 636px;
display: flex;
padding-left: 40px;
margin-left: 190px;
object-position: cover;
}

.access-rg{
margin-left: 100px;
}

.access-rgg{
margin-left: 100px;
margin-top: 120px;
}

.access-text h1{
font-family: 'Montserrat' !important;
font-size: 34px;
font-style: normal !important;
font-weight: 600 !important;
color: #140740 !important;
text-align: left;
padding-bottom: 30px;
}

.access-text p{
font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 600 !important;
color: #333333 !important;
font-size: 16px;
text-align: left;
}
/* --------------------ACHIEVE ENDS------------------*/

/* --------------------START US STARTS------------------*/
.dedukttimgg{
    width: 500px;
    height: 500px;
}

.start-padding{
    padding-top: 125px;
    padding-bottom: 125px;
}

.start-big{
    font-size: 34px;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
  font-weight: 700 !important;
  color: #3E3E3F;
    padding-bottom: 80px;
}

.start-small h3 {
    font-family: 'Montserrat' !important;
    font-size: 16px;
    font-style: normal !important;
    font-weight: 700 !important;
    color: #8780C5 !important;
    text-align: center;
    padding-bottom: 10px;
}

.start-small h1 {
    font-family: 'Montserrat' !important;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    color: rgba(135, 128, 197, 0.3) !important;
    text-align: center;
    margin-top: 20px;
}

/*.start-move span, .start-move hr{
    text-align: center !important;
}*/

.round{
    font-family: 'Montserrat' !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
    background-color: #8780C5;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px !important;
    margin-top: 10px;
    margin-left: 150px;
}

.header-round {
    width: 100px;
    height: 100px;
    border-radius: 60%;
    justify-content: center;
    align-items: center;
}

.headerround {
    width: 100px;
    height: 100px;
    border-radius: 60%;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
  padding-left: 80px;
}
/*.flex-grow-1 .round {
     white-space: nowrap !important;
  text-align: center;
}*/

/* --------------------START US ENDS------------------*/

/* --------------------GET SUPPORT STARTS------------------*/
.support-padding{
    padding-top: 250px;
    padding-bottom: 150px;
    margin-left: 50px;
  margin-right: 50px;
}

.support-big{
    font-size: 34px;
    font-family: 'Montserrat' !important;
font-style: normal !important;
font-weight: 700 !important;
color: #00B7D0 !important;
padding-bottom: 20px;
}

.support-small{
    font-family: 'Montserrat' !important;
    font-size: 16px;
    font-style: normal;
  font-weight: 500;
  color: #333333;
}

.support-logo{
        background-image: url('assets/supportimg.png');
        background-position:center !important;
        background-repeat:no-repeat !important;
        background-size:cover !important;
        height: 200px;
        max-width: 100% !important;
        border-radius: 10px;
        justify-content: center !important;
        align-items: center !important;
    position: relative;
}

.form-cont{
    position: absolute;
  right: 0;
  margin-right: 50px;
    border-radius: 10px;
  max-width: 400px;
    height: 480px;
    margin-top: -320px; 
  /*padding: 16px;*/
  background-color: white;
}

.form-contt{
    position: absolute;
  right: 0;
  margin-right: 60px;
    border-radius: 10px;
  max-width: 528px;
    height: 482px;
    margin-top: -250px; 
  /*padding: 16px;*/
  background-color: white;
}

.fom-row{
    margin-top: -18px;
}
.form-name .form-label {
    margin-left: 30px;
    font-size: 14px;
    font-family: 'Montserrat';
    font-style: normal;
  font-weight: 500;
  color: #333333;
}

.form-namee .form-label {
    margin-left: 30px;
}

.form-name .form-control{
    width: 150px;
    margin-left: 30px;
    border: 1px solid #00B7D0;
}

.form-namee .form-control{
    width: 200px;
    margin-left: 30px;
    }

.form-mail .form-control{
    width: 150px;
    margin-right: 30px;
    border: 1px solid #00B7D0;
}

.form-maill .form-control{
    width: 210px;
    margin-right: 30px;
    margin-left: 10px;
}

.form-maill .form-label{
margin-left: 30px;
}

.form-mail .form-label{
    font-size: 14px;
    font-family: 'Montserrat'!important;
    font-style: normal;
  font-weight: 500;
  color: #333333;
}

/*.form-maill .form-label{
    font-size: 14px;
    font-family: 'Montserrat'!important;
    font-style: normal;
  font-weight: 500;
  color: #333333;
}*/

.form-msg .form-label{
    margin-left: 30px;
    font-size: 14px;
    font-family: 'Montserrat'!important;
    font-style: normal;
  font-weight: 500;
  color: #333333;
}

.form-msg .form-control{
    height: 150px;
    width: 340px;
    margin-left: 30px;
    border: 1px solid #00B7D0;
}

.form-msgg .form-control{
    height: 150px;
    width: 460px;
    margin-left: 30px;
}

.form-msgg .form-label{
    margin-left: 30px;
}

.form-btn{
    /*padding-left: 150px;*/
    width: 340px;
    margin-left: 20px;
    margin-bottom: 30px;
    background-color: #00B7D0;
    color: #FFFFFF;
}

.form-btnn{
    /*padding-left: 150px;*/
    width: 460px;
    margin-left: 20px;
    margin-bottom: 30px;
    color: #FFFFFF;
}
/* --------------------GET SUPPORT ENDS------------------*/

/* -------------------- NEW FOOTER STARTS -------------- */
.bg-ellipses{
    background-image: url('https://res.cloudinary.com/dedukt/image/upload/v1643398297/footerellipses_z1goub.png');
                background-position: right;
                background-repeat: no-repeat;
                background-size: contain;
}
/* --------------------NEW FOOTER ENDS -------------- */

/* --------------------FOOTER STARTS -------------- */
.footer-section{
                /* position: absolute; */
                /* bottom: 0; */
                width: 100%;
                color: #FFFFFF;
                padding-top: 50px;
                padding-bottom: 20px;
                background: #3E3E3F;
                background-image: url('https://res.cloudinary.com/dedukt/image/upload/v1643398297/footerellipses_z1goub.png');
                background-position: right;
                background-repeat: no-repeat;
                background-size: contain;

            }
            
             .footer-logo{
                background-image: url('assets/deduktlogo.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 100px;
                height: 100px;
             }
            
/* -------------------- FOOTER ENDS --------------------- */
